// export const baseURL = "https://devsellerapi.aapkabazar.co/api/seller/";
// export const baseURL = 'http://52.66.228.220:13002/api/admin';
// export const baseURL = 'http://localhost:13002/api/admin';
export const baseURL = "https://devadminapi.aapkabazar.co/api/admin";
export const imgUrl = "https://image.aapkabazar.co";
export const liveURL = "https://devadminapi.aapkabazar.co/api/admin";
export const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxN2QyOTgyYmQ2OGM5NGQwYmNiOTIwMCIsInBob25lTm8iOjg3ODc4Nzg3ODcsImVtYWlsIjoiYWFwa2FiYXphcnNlbGxlckBnbWFpbC5jb20iLCJpYXQiOjE3MDc0ODkwMzN9.NziQ8vjNz5y42pFtsq6739vo7GwOVuOzVsX0hcFrq0Q";
export const headers = {
  headers: {
    "x-access-token": token,
  },
};
export const pageLimit = 40;
