import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../assets/logo.jpg";
import UserSvg from "../../assets/avatar.svg";
import dashboardSvg from "../../assets/dashboard.svg";
import orderSvg from "../../assets/order.svg";
import categorySvg from "../../assets/category.svg";
import OfferIcon from "../../assets/OfferIcon.svg";
import SellerIcon from "../../assets/SellerIcon.svg";
import TagIcon from "../../assets/TagIcon.svg";
import BrandsIcon from "../../assets/BrandsIcon.svg";
import DesignIcon from "../../assets/DesignIcon.svg";
import productSvg from "../../assets/product.svg";
import bulkOrderSvg from "../../assets/bulkOrder.svg";
import customerSvg from "../../assets/customer.svg";
import deliveryBoySvg from "../../assets/deliveryBoy.svg";
import deliverySlotSvg from "../../assets/deliverySlot.svg";
import deliveryCharge from "../../assets/deliveryCharge.svg";
import addNewProduct from "../../assets/addNewProduct.svg";
import disableSlot from "../../assets/disableSlot.svg";
import admin from "../../assets/admin.svg";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../screens/Dashboard/Dashboard";
import IAM from "../../screens/IAM/IAM";
import Page404 from "../../screens/404/404";
import AppHomeScreen from "../../screens/AppHomeScreen/AppHomeScreen.tsx";
import Category from "../../screens/Category/Category.tsx";
import PromoCode from "../../screens/Promocode/PromoCode.tsx";
import Products from "../../screens/Products/Products.tsx";
import { SingleProductPage } from "../../screens/Products/SingleProductPage.tsx";
import Seller from "../../screens/Sellers/Seller.tsx";
import SinglePromocode from "../../screens/Promocode/SinglePromocode.tsx";
import Tags from "../../screens/Tags/Tags.tsx";
import Orders from "../../screens/Orders/Orders.js";
import Brands from "../../screens/Brands/Brands.js";
import Complaints from "../../screens/Complaints/Complaints.tsx";
import ComplaintDetail from "../../screens/Complaints/ComplaintDetail.tsx";
import ServingArea from "../../screens/ServingArea/ServingArea.tsx";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const userPermissions = JSON.parse(localStorage.getItem('permissions'));

// Get today's date
const today = new Date();
const todayDay = today.getDate().toString().padStart(2, "0");
const todayMonth = (today.getMonth() + 1).toString().padStart(2, "0");
const todayYear = today.getFullYear().toString();
const formattedToday = `${todayYear}-${todayMonth}-${todayDay}`;

// Get yesterday's date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const yesterdayDay = yesterday.getDate().toString().padStart(2, "0");
const yesterdayMonth = (yesterday.getMonth() + 1).toString().padStart(2, "0");
const yesterdayYear = yesterday.getFullYear().toString();
const formattedYesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const location = useLocation();
  const [selectedContent, setSelectedContent] = useState(location.pathname);
  console.log(location.pathname);
  const [menu, setMenu] = useState([]);

  const setMenuItem = () => {
    let menuItems = [];
    let permissions = userPermissions
    if (permissions || true) {
      // if (true ||permissions.some(permission => permission.name === 'admin') ) {
      if (true) {
        menuItems.push({ title: "Dashboard", icon: dashboardSvg, route: "/" },
          // { title: "Orders", icon: orderSvg, route: "/orders" },
          { title: "Products", icon: productSvg, route: "/products" }, 
          { title: "Category", icon: categorySvg, route: "/category" },
          { title: "Complaints", icon: categorySvg, route: "/complaints" },
          { title: "Promocode", icon: OfferIcon, route: "/promocode" },
          { title: "Seller", icon: SellerIcon, route: "/seller" },
          { title: "ServingArea", icon: SellerIcon, route: "/servingarea" },
          { title: "Tags", icon: TagIcon, route: "/tags" },
          { title: "Brands", icon: BrandsIcon, route: "/brands" },
          { title: "Homescreen", icon: DesignIcon, route: "/apphomescreen" }, 
        )
        setMenu(menuItems);
        return;
      }


      if (permissions.some(permission => permission.name === 'dashboard' && permission.read === true)) {
        menuItems.push({ title: "Dashboard", icon: dashboardSvg, route: "/" });
      }
      if (permissions.some(permission => permission.name === 'orders' && permission.read === true)) {
        menuItems.push({ title: "Orders", icon: orderSvg, route: "/orders" });
      }

      if (permissions.some(permission => permission.name === 'products' && permission.read === true)) {
        menuItems.push({ title: "Products", icon: productSvg, route: "/products" });
      }
      if (permissions.some(permission => permission.name === 'newproduct' && permission.read === true)) {
        menuItems.push({ title: "Add New Product", icon: addNewProduct, route: "/newProduct" });
      }
      if (permissions.some(permission => permission.name === 'category' && permission.read === true)) {
        menuItems.push({ title: "Category", icon: categorySvg, route: "/category" });
      }
      if (permissions.some(permission => permission.name === 'bulkorder' && permission.read === true)) {
        menuItems.push({ title: "Bulk Orders", icon: bulkOrderSvg, route: "/bulkorders" });
      }
      if (permissions.some(permission => permission.name === 'customers' && permission.read === true)) {
        menuItems.push({ title: "Customers", icon: customerSvg, route: "/customers" });
      }
      if (permissions.some(permission => permission.name === 'deliveryboy' && permission.read === true)) {
        menuItems.push({ title: "Delivery Boy", icon: deliveryBoySvg, route: "/deliveryboy" });
      }
      if (permissions.some(permission => permission.name === 'iam' && permission.read === true)) {
        menuItems.push({ title: "Account", icon: admin, route: "/iam" });
      }
      if (permissions.some(permission => permission.name === 'deliveryslot' && permission.read === true)) {
        menuItems.push({ title: "Delivery Slot", icon: deliverySlotSvg, route: "/deliveryslot" });
      }
      if (permissions.some(permission => permission.name === 'deliverycharges' && permission.read === true)) {
        menuItems.push({ title: "Delivery Charge", icon: deliveryCharge, route: "/deliverycharges" });
      }
      if (permissions.some(permission => permission.name === 'disableslot' && permission.read === true)) {
        menuItems.push({ title: "Disable Slot", icon: disableSlot, route: "/disableslot" });
      }
    }
    setMenu(menuItems);
  }

  useEffect(() => {
    setMenuItem();
  }, [])
  const navigate = useNavigate();
  const renderContent = () => {
    return (
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/iam" element={<IAM />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/apphomescreen" element={<AppHomeScreen />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product" element={<SingleProductPage />} />
        <Route path="/category" element={<Category />} />
        <Route path="/servingarea" element={<ServingArea />} />
        <Route path="/complaint/:id" element={<ComplaintDetail />} />
        <Route path="/complaints" element={<Complaints />} />
        <Route path="/seller" element={<Seller />} />
        <Route path="/promocode" element={<PromoCode />} />
        <Route path="/promocode/:id" element={<SinglePromocode />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    );
  };
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar elevation={0} position="fixed" open={open}>
        <Toolbar style={{ backgroundColor: "white", justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' , alignItems: 'center'}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                color: "#696969",
                boxShadow: "none",
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
            <img style={{ height: 40, width: 40 , borderRadius: '50%'}} src={logo} id="logo" alt="AkbLogo" />
            <div style={{ width: 20 }} />
            <Typography color={"black"} variant="h6" noWrap component="div">
              Admin Panel <strong>AKB</strong>
            </Typography>
          </div>
          <button style={{ cursor: "pointer", backgroundColor: '#00000005', border: '1px solid #0000001A', padding: '12px 25px', borderRadius: '15px' }} onClick={() => { localStorage.removeItem('token2'); window.location.reload(); }} >Logout</button>
        </Toolbar>
      </AppBar>
      <Drawer style={{ borderRight: "none" }} variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "white", display: "flex", borderRight: "none", justifyContent: "center", alignItems: "center", gap: 2 }}>
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <img style={{ height: 40, width: 40, borderRadius: "50%" }} src={UserSvg} alt="user" id="profileImage" />
          </div>
          <div>
            <div style={{ fontSize: 14 }}>Subodh Avasthi</div>
            <div style={{ fontSize: 12 }}>Store Manager</div>
          </div>
          <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        <List style={{ borderRight: "none" }}>
          {menu?.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: "block",
                paddingBottom: 0.5,
              }}>
              <ListItemButton
                onClick={() => {
                  setSelectedContent(item.route);
                  navigate(item.route, { state: { page: 1, snf: { sortField: "totalOrders", sortOrder: -1 } } });
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,

                  backgroundColor: item.route === selectedContent ? "#ccc" : "transparent",
                  "&:hover": {
                    backgroundColor: item.route !== selectedContent ? "rgba(0,0,0,0.2)    " : "#ccc",
                  },
                }}>
                <img
                  alt="hello"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={item.icon}
                />
                <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0, paddingLeft: 1 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flex: 1,
          minWidth: 1000,
        }}>
        <DrawerHeader />
        {renderContent()}
      </Box>
    </Box>
  );
}
