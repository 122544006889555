import axios from "axios"
import { getItem } from "../utils/storage"
import { baseURL } from "../config/config";

export const getIAMUser = async () => {
    try {

        const token = getItem('token');
        const response = await axios.get(`${baseURL}sellerUser`, {
            headers: {
                "x-access-token": token
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }
    catch (error) {
        console.log(error);
    }
}
export const changeActiveIAM = async (id,active) => {

    try {
        const token = getItem('token');
        const response = await axios.post(`${baseURL}sellerUser/active`,{
            id,
            active: active
        }, {
            headers: {
                "x-access-token": token
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const updateUser = async ( data) => {
    try {
        const token = getItem('token');
        const response = await axios.put(`${baseURL}sellerUser`, data, {
            headers: {
                "x-access-token": token
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }
    catch (error) {
        console.log(error);
    }
}
export const createUser = async ( data) => {
    try {
        const token = getItem('token');
        const response = await axios.post(`${baseURL}sellerUser`, data, {
            headers: {
                "x-access-token": token
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }
    catch (error) {
        console.log(error);
    }
}