import React, { useEffect, useState } from 'react';
import { getDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Complaints = () => {

    const [issues, setIssues] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const getData = async() => {
            try {
                const result = await getDataSec(`${baseURL}/issues?start=0&limit=40`)
                if (result.success) {
                    setIssues(result.issueList)
                } else {
                    toast.error("Cannot fetch complaints")
                }
            } catch (err) {
                console.log("error in fetching complaints :", err)
            }
        }
        getData();
    }, [])

    const renderComplaints = () => {
        return issues.map((item,index)=>{
                return  <tr key={index} onClick={()=>{
                    navigate('/complaint/'+item._id)
                }}>
                    <td>{item.id}</td>
                    <td>{item.order?.id}</td>
                    <td>{item.phoneNo}</td>
                    <td>{item.date}</td>
                    <td style={{maxWidth: 300}}>{item.type}</td>
                    <td style={{maxWidth: 300}}>{item.status}</td>
                </tr>
            })
    }

    return (
        <div style={{display: 'flex', flex: 1, padding: 20, overflow: 'scroll'}}>
            {
                issues.length > 0 ? 
                <div style={{display: 'flex', flex: 1, padding: 20, backgroundColor: 'white', borderRadius: 12}}>
                    <table className='promocodeContainer'>
                        <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Date</th>
                                <th>Phone No</th>
                                <th>Date</th>
                                <th>Subject</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderComplaints()}
                        </tbody>
                    </table>
                </div>: 
                <></>

            }
        </div>
    );
}

export default Complaints;
