import React, { useEffect, useState } from 'react';
import { getDataSec, postDataSec, putDataSec } from '../../Apis/Api';
import { baseURL } from '../../config/config';
import { toast } from 'react-toastify';
import './Tags.css';

const Tags = () => {

    const [tagList, setTagList] = useState([]);
    const [selectedTag, setSelectedTag] = useState()
    const [addNew, setAddNew] = useState(false);

    const getTags = async () => {
        try {
            const result = await getDataSec(baseURL + '/taglist');
            if (result.data) {
                setTagList(result.data);
            } else {
                toast.error("Unable to fetch tags");
                console.log(result.message);
            }
        } catch (err) {
            console.log("error in fetching tags:", err);
            toast.error("Error while fetching tags");
        }
    }
    useEffect(() => {
        getTags();
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const copyTagLink = (itemId) => {
        navigator.clipboard.writeText(`https://aapkabazar.co/tags/${itemId}`).then(() => {
            toast.success("Copied to clipboard");
        })
    }

    const modifyTag = async () => {
        try {
            if (addNew) {
                const data = {
                    isActive: selectedTag.isActive,
                    tag_name: selectedTag.name
                }
                const result = await postDataSec(baseURL + '/addTags', data);
                if (result.success) {
                    toast.success(result.message);
                    getTags();
                    setAddNew(false);
                    setSelectedTag({ _id: null, name: '', isActive: true });
                } else {
                    toast.error(result.message);
                }
                return;
            }

            if (selectedTag._id && selectedTag._id === null) {
                toast.error("Tag Id not found");
            }
            const data = {
                isActive: selectedTag.isActive,
                tag_id: selectedTag._id,
                tag_name: selectedTag.name
            }

            const result = await putDataSec(baseURL + '/updateTags', data);
            if (result.success) {
                toast.success(result.message);
                getTags();
            } else {
                toast.error(result.message);
            }


        } catch (err) {
            console.log("error in updating tag", err);
            toast.error("Error in updating tags")
        }
    }

    return (
        <div style={{ display: 'flex', flex: 1, padding: 20, gap: 20 }}>
            <div className='tagListContainer'>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                    <h3>Tags List</h3>
                    <button
                        onClick={() => { setSelectedTag({ _id: null, name: '', isActive: true }); setAddNew(true) }}
                        style={{ border: '1px solid #eee', padding: '10px 20px', width: 120, marginBottom: 10, backgroundColor: '#ccc', borderRadius: 8, alignSelf: 'flex-end' }}>
                        ADD NEW +
                    </button>
                </div>
                {tagList.map((item, index) => {
                    return (<button className='tagListItem' >
                        <p style={{ textTransform: 'capitalize' }}>{index + 1} {item.name}</p>
                        <div style={{ gap: 10, display: 'flex' }}>
                            <button
                                onClick={() => { setSelectedTag(item); setAddNew(false); }}
                                style={{
                                    border: '1px solid #eee',
                                    padding: '10px 20px',
                                    width: 120,
                                    marginBottom: 10,
                                    backgroundColor: '#ccc',
                                    borderRadius: 8
                                }}>Edit</button>
                            <button
                                onClick={() => { copyTagLink(item._id) }}
                                style={{
                                    border: '1px solid #eee',
                                    padding: '10px 20px',
                                    width: 120,
                                    marginBottom: 10,
                                    backgroundColor: '#ccc',
                                    borderRadius: 8
                                }}>Copy Link</button>
                        </div>
                    </button>)
                })}
            </div>
            <div className='tagEditorContainer'>
                {
                    selectedTag ?
                        <div>

                            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                <label>Tag Name</label>
                                <input value={selectedTag.name} onChange={(e) => { setSelectedTag((prev): any => ({ ...prev, name: e.target.value })) }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                <label>Active</label>
                                <input type='checkbox' checked={selectedTag.isActive} onChange={() => {
                                    setSelectedTag((prev): any => ({
                                        ...prev,
                                        isActive: !prev.isActive
                                    }));
                                }} />
                            </div>

                            <button className='SaveBtn' onClick={() => { modifyTag() }}>Save</button>
                        </div>
                        : <></>
                }
            </div>
        </div>
    );
}

export default Tags;
