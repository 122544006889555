import axios from "axios";
import React, { useEffect } from "react";
import { baseURL, imgUrl } from "../../config/config";
import "./AppHomeScreen.css";
import { toast } from "react-toastify";
import { CardGiftcard } from "@mui/icons-material";

interface card {
  position: number;
  type: string;
  title: string;
  categories: { categoryId: string }[];
  image: {
    imgUrl: string;
    productId: string;
    keyword: string;
    productUrl: string;
  }[];
  categoryItems: { categoryId: string };
  gifUrl: string;
  popularproducts: { productId: string }[];
}

function AppHomeScreen() {
  const [cards, setCards] = React.useState<card[]>([]);
  const [selectedCard, setSelectedCard] = React.useState<any>();
  const [categories, setCategories] = React.useState<any>([]);
  const [addCard, setAddCard] = React.useState(true);

  const getCards = async () => {
    try {
      const response = await axios.get(`${baseURL}/listHomeScreenCard`);
      if (response.data.success) {
        let data = response.data.cards;
        data.sort((a, b) => a.position - b.position);
        setCards(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    const fetchAllCategories = async () => {
      try {
        const response = await axios.get(
          `https://api.aapkabazar.co/api/root/category?cityId=619f219d26d9ad0f34102dd2`
        );
        if (response.data.success) {
          let data = response.data.category;
          setCategories(data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllCategories();
    getCards();

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    console.log(selectedCard);
  }, [selectedCard]);

  const returnCategoryCard = (card, index) => {
    return (
      <div
        onClick={() => {
          setSelectedCard(card);
          setAddCard(false);
        }}
        style={{
          display: "flex",
          backgroundColor: "white",
          marginTop: 20,
          flex: 1,
          flexWrap: "wrap",
          borderRadius: 12,
          padding: "10px 0 10px 0",
          cursor: "pointer",
        }}
      >
        {card?.categories?.map((category, index) => {
          return (
            <div
              className="card"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: "5px 20px 5px 20px",
                gap: 10,
              }}
            >
              <div
                style={{ display: "flex", flex: 1, justifyContent: "center" }}
              >
                <img
                  src={`${imgUrl}/cat/${category.id}/${category.images}`}
                  style={{ height: 50, width: 50 }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  fontSize: 14,
                  textTransform: "capitalize",
                }}
              >
                {category.name}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const BannerCard = (card, index) => {
    console.log(`${imgUrl}/banner/${card.image[0].imgUrl}`)
    return (
      <div
        onClick={() => {
          setSelectedCard(card);
          setAddCard(false);
        }}
        className="card"
        style={{
          display: "flex",
          marginTop: 20,
          flex: 1,
          flexWrap: "wrap",
          overflow: 'hidden'
        }}
      >
        <img src={`${imgUrl}/banner/${card.image[0].imgUrl}`} style={{ width: "100%" }} />
      </div>
    );
  };
  const gifCard = (card, index) => {
    return (
      <div
        onClick={() => {
          setSelectedCard(card);
          setAddCard(false);
        }}
        className="card"
        style={{
          display: "flex",
          backgroundColor: "red",
          marginTop: 20,
          flex: 1,
          flexWrap: "wrap",
        }}
      >
        <img src={card.gifUrl} style={{ width: "100%" }} />
      </div>
    );
  };

  const productCard = (card, index) => {
    console.log(card);
    return (
      <div
        onClick={() => {
          setSelectedCard(card);
          setAddCard(false);
        }}
        className="card"
        style={{
          display: "flex",
          backgroundColor: "white",
          marginTop: 20,
          flex: 1,
          flexWrap: "wrap",
          borderRadius: 12,
          padding: "20px 10px ",
          cursor: "pointer",
        }}
      >
        <p>Products of {card.categoryItems.categoryName}</p>
      </div>
    );
  };

  const renderCards = () => {
    if (cards.length === 0) {
      return <h1>No cards found</h1>;
    }
    return cards?.map((card, index) => {
      switch (card.type) {
        case "category":
          return returnCategoryCard(card, index);
        case "horizontalbanner":
        case "verticalbanner":
          return BannerCard(card, index);
        case "product":
          return productCard(card, index);
        case "gif":
          return gifCard(card, index);
        default:
          return <></>;
      }
    });
  };

  const handleAddcard = async () => {
    setSelectedCard({
      position: 0,
      type: "",
      title: "",
      categories: [],
      image: [],
      categoryItems: { categoryId: "" },
      gifUrl: "",
      popularproducts: [],
    });
    setAddCard(true);
  };

  const AddCardFunction = async () => {
    try {
      if (!selectedCard.type) {
        toast.error("Please select card type");
        return;
      }
      if (!selectedCard.position) {
        toast.error("Please enter position");
        return;
      }
      if (!selectedCard.title) {
        toast.error("Please enter title");
        return;
      }
      if (addCard) {
        const response = await axios.post(
          `${baseURL}/addHomeScreenCard`,
          selectedCard
        );
        if (response.status === 200) {
          toast.success("Card added successfully");
          getCards();
        } else {
          toast.error("error in adding new card");
        }
      } else {
        const response = await axios.post(
          `${baseURL}/modifyHomeScreenCard`,
          selectedCard
        );
        if (response.status === 200) {
          toast.success("Card updated successfully");
          getCards();
        } else {
          toast.error("error in updating card");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTypeChange = async (e) => {
    let value = e.target.value;
    setSelectedCard((prev) => ({ ...prev, type: value }));
  };

  const handleCategoryADD = (e) => {
    let value = e.target.value;
    let newCategory = selectedCard.categories || [];
    if (newCategory?.some((category) => category.categoryId === value)) {
      newCategory = newCategory.filter(
        (category) => category.categoryId !== value
      );
    } else {
      newCategory.push({ categoryId: value });
    }
    setSelectedCard((prev) => ({ ...prev, categories: newCategory }));
  };

  const handleSearchPageBanners = () => {
    let temp: any = cards.find((card) => card.type === "searchpagebanners");
    if (!temp) {
      temp = {
        position: 0,
        type: "searchpagebanners",
        title: "Search Page Banners",
        image: [],
      };
    }
    setAddCard(false);
    setSelectedCard(temp);
  };

  const handlePopularProducts = () => {
    setAddCard(false);
    let temp = cards.find((card) => card.type === "popularproducts");
    setSelectedCard(temp);
  };

  const uploadImage = (id) => {
    const file: any = document.getElementById(id) as HTMLInputElement;
    const formData = new FormData();
    formData.append("image", file?.files[0]);
    axios.put(`${baseURL}/upload`, formData, {
      headers: {
        "x-access-token": localStorage.getItem("token") || "",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Image Uploaded Successfully")
        const newImages = [...selectedCard.image];
        newImages.find((image) => image._id === id).imgUrl = res.data.path.filename;
        newImages.find((image) => image._id === id).tempimgUrl = res.data.path.filename;
        setSelectedCard((prev) => ({
          ...prev,
          image: newImages,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const deleteCard = async () => {
    try {
      const response = await axios.post(`${baseURL}/deleteHomeScreen`, { _id: selectedCard._id });
      if (response.status === 200) {
        toast.success("Card deleted successfully");
        getCards();
        setSelectedCard(null);
      } else {
        toast.error("error in deleting card");
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        padding: 20,
        gap: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 100px)",
          width: 350,
          backgroundColor: "#f4e9cd",
          flexDirection: "column",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "calc(100%-40px)",
            margin: 5,
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={handleAddcard}
            style={{
              borderRadius: 4,
              border: 0,
              backgroundColor: "white",
              padding: "10px 12px",
            }}
          >
            Add more
          </button>
        </div>
        <div>{renderCards()}</div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          maxHeight: "calc(100vh - 100px)",
          gap: 10,
          flexDirection: "column",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        {selectedCard && (
          <>
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                minHeight: 30,
                justifyContent: "flex-end",
                padding: 5,
                gap: 20,
              }}
            >
              <button
                onClick={() => handlePopularProducts()}
                style={{
                  backgroundColor: "rgb(238 238 238 / 56%)",
                  padding: "0px 10px",
                  borderRadius: 4,
                  border: "1px solid #ddd",
                }}
              >
                Change Search page popular products
              </button>
              <button
                onClick={() => handleSearchPageBanners()}
                style={{
                  backgroundColor: "rgb(238 238 238 / 56%)",
                  padding: "0px 10px",
                  borderRadius: 4,
                  border: "1px solid #ddd",
                }}
              >
                Change Search Bar Banners
              </button>
            </div>
            {selectedCard.type === "popularproducts" ? (
              <div className="AddCardDiv">
                <h3>Popular Products</h3>
                {selectedCard?.popularproducts?.map((product, index) => (
                  <div style={{ alignItems: "center" }}>
                    <label>Product {index + 1}</label>
                    <input
                      value={product.productId}
                      placeholder="product object id"
                      onChange={(e) => {
                        setSelectedCard((prev) => {
                          let temp = [...prev.popularproducts];
                          temp[index] = { productId: e.target.value };
                          return { ...prev, popularproducts: temp };
                        });
                      }}
                    />
                    {product.name}
                  </div>
                ))}
                <button
                  onClick={() =>
                    setSelectedCard((prev) => ({
                      ...prev,
                      popularproducts: [
                        ...prev.popularproducts,
                        { productId: "" },
                      ],
                    }))
                  }
                >
                  Add Product
                </button>
                <button
                  onClick={AddCardFunction}
                  style={{
                    width: 70,
                    borderRadius: 12,
                    padding: "10px 20px",
                    border: 0,
                  }}
                >
                  MODIFY
                </button>
              </div>
            ) : (
              <div className="AddCardDiv">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="inputBoxDiv">
                    <label>Position</label>
                    <input
                      type="number"
                      value={selectedCard?.position}
                      onChange={(e) => {
                        setSelectedCard((prev) => ({
                          ...prev,
                          position: e.target.valueAsNumber,
                        }));
                      }}
                    />
                  </div>
                  <div className="inputBoxDiv">
                    <label>Card Name</label>
                    <input
                      value={selectedCard.title}
                      type="text"
                      onChange={(e) =>
                        setSelectedCard((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="inputBoxDiv">
                    <label>Card Type</label>
                    <select
                      onChange={handleTypeChange}
                      value={selectedCard.type}
                    >
                      <option value="">Select type</option>
                      <option value="category">Category</option>
                      <option value="horizontalbanner">
                        Horizontal Banner
                      </option>
                      <option value="verticalbanner">Vertical Banner</option>
                      <option value="product">Products of a Category</option>
                      <option value="gif">Gif</option>
                    </select>
                  </div>
                </div>
                {(selectedCard?.type === "horizontalbanner" ||
                  selectedCard?.type === "verticalbanner" ||
                  selectedCard?.type === "searchpagebanners" ||
                  selectedCard?.type === "categorypagebanners") && (
                    <div
                      style={{
                        flexDirection: "column",
                        gap: 10,
                        margin: "5px 0",
                      }}
                    >
                      {selectedCard?.image?.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #ccc",
                            padding: 20,
                            borderRadius: 12,
                          }}
                        >
                          {/* <img src={``}/> */}
                          <div className="inputDiv" style={{ justifyContent: 'space-between' }}>
                            <select value={image?.type} onChange={(e) => {
                              const newImages = [...selectedCard.image];
                              newImages[index] = {
                                ...newImages[index],
                                type: e.target.value,
                              };
                              setSelectedCard((prev) => ({
                                ...prev,
                                image: newImages,
                              }));
                            }}>
                              <option value="category">Category</option>
                              <option value="product">Product</option>
                              <option value="tag">Tag</option>
                            </select>
                            <button
                              onClick={() => {
                                const temp = [
                                    ...selectedCard.image.slice(0, index), 
                                    ...selectedCard.image.slice(index + 1)
                                ];
                                setSelectedCard((prev) => ({ ...prev, image: temp }));
                            }}                            
                              style={{
                                borderRadius: 12,
                                maxWidth: '100px',
                                padding: "5px 10px",
                                border: 0,
                                backgroundColor: 'rgb(244, 96, 96)',
                                color: 'white'
                              }}>Delete</button>
                          </div>

                          <img src={`${imgUrl}/banner/${image.imgUrl}`} style={{ height: 100, objectFit: 'contain' }} />
                          <div className="inputDiv" >
                            <label>Image URL</label>
                            <input type="file" id={`${image._id}`} placeholder={image.imgUrl} />
                            <button style={{ padding: '10px 20px', borderRadius: 12, backgroundColor: '#eee', border: 0 }} onClick={() => { uploadImage(image._id) }} >Upload</button>
                          </div>
                          <div className="inputDiv" >
                            <label>ID</label>
                            <input
                              required
                              type="text"
                              placeholder={`Enter ${image.type || 'category'} Id`}
                              value={image.productId || ""}
                              onChange={(e) => {
                                const newImages = [...selectedCard.image];
                                newImages[index] = {
                                  ...newImages[index],
                                  productId: e.target.value,
                                };
                                setSelectedCard((prev) => ({
                                  ...prev,
                                  image: newImages,
                                }));
                              }}
                            />
                          </div>
                          <div className="inputDiv" >
                            <label>Keyword</label>
                            <input
                              required
                              type="text"
                              placeholder="Enter keyword"
                              value={image.keyword || ""}
                              onChange={(e) => {
                                const newImages = [...selectedCard.image];
                                newImages[index] = {
                                  ...newImages[index],
                                  keyword: e.target.value,
                                };
                                setSelectedCard((prev) => ({
                                  ...prev,
                                  image: newImages,
                                }));
                              }}
                            />
                          </div>
                          <div className="inputDiv" >
                            <label>Url</label>
                            <input
                              required
                              type="text"
                              placeholder="Enter product Url"
                              value={image.productUrl || ""}
                              onChange={(e) => {
                                const newImages = [...selectedCard.image];
                                newImages[index] = {
                                  ...newImages[index],
                                  productUrl: e.target.value,
                                };
                                setSelectedCard((prev) => ({
                                  ...prev,
                                  image: newImages,
                                }));
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        onClick={() =>
                          setSelectedCard((prev) => {
                            console.log(prev);
                            try {
                              return { ...prev, image: [...prev?.image, {}] };
                            } catch (err) {
                              console.log(err);
                              return { ...prev, image: [{}] };
                            }
                          })
                        }
                      >
                        Add Image
                      </button>
                    </div>
                  )}
                {selectedCard?.type === "category" && (
                  <div>
                    <label>Category</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: 20,
                        justifyContent: "center",
                      }}
                    >
                      {categories?.map((category, index) => {
                        console.log(selectedCard.categories, category._id);
                        const flag = selectedCard.categories?.some(
                          (item) => category._id === item.categoryId
                        );
                        console.log(flag);
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 20,
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={flag}
                              value={category._id}
                              onChange={handleCategoryADD}
                            />
                            <label>{category.name}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {selectedCard?.type === "product" && (
                  <div>
                    <label>Category Card</label>
                    <select
                      value={selectedCard?.categoryItems?.categoryId}
                      onChange={(e) => {
                        setSelectedCard((prev: card) => ({
                          ...prev,
                          categoryItems: { categoryId: e.target.value },
                        }));
                      }}
                    >
                      <option value="">Select Category</option>
                      {categories?.map((category, index) => {
                        return (
                          <option value={category._id}>{category.name}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {selectedCard?.type === "gif" && (
                  <div>
                    <label>Gif</label>
                    <input
                      type="text"
                      value={selectedCard?.gifUrl}
                      onChange={(e) => {
                        setSelectedCard((prev: card) => ({
                          ...prev,
                          gifUrl: e.target.value,
                        }));
                      }}
                    />
                    <input type="file" />
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <button
                    onClick={AddCardFunction}
                    style={{
                      borderRadius: 12,
                      maxWidth: '100px',
                      padding: "10px 20px",
                      border: 0,
                    }}
                  >
                    {addCard ? "ADD" : "MODIFY"}
                  </button>
                  <button
                    onClick={deleteCard}
                    style={{
                      borderRadius: 12,
                      maxWidth: '100px',
                      padding: "10px 20px",
                      border: 0,
                      backgroundColor: 'rgb(244, 96, 96)',
                      color: 'white'
                    }}>Delete</button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AppHomeScreen;
